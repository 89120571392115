<script lang="ts">
    import Table from '$lib/components/ui/table/table.svelte';
    import TableBody from '$lib/components/ui/table/table-body.svelte';
    import TableCaption from '$lib/components/ui/table/table-caption.svelte';
    import TableCell from '$lib/components/ui/table/table-cell.svelte';
    import TableHead from '$lib/components/ui/table/table-head.svelte';
    import TableHeader from '$lib/components/ui/table/table-header.svelte';
    import TableRow from '$lib/components/ui/table/table-row.svelte';

    export let data = [
        {
            postId: '1237938',
            publishingTime: '12.12.2024',
            caption: 'Paris!',
            mediaPreview: ['/svg/bmn.svg', '/svg/bmn.svg'],
            status: 'manual review',
        },
    ];
</script>

<div class="w-full overflow-x-auto md:w-[85%]">
    <Table class="h-full w-full text-[#FFFFFF]">
        <TableHeader class="border-none">
            <TableRow class="border-none hover:bg-transparent ">
                <TableHead>
                    <div class="flex h-full w-full items-center gap-1 dark:text-white">
                        <span class="flex flex-col gap-[1px] leading-none">
                            <span> &#x25B2;</span>
                            <span> &#x25BC; </span>
                        </span>
                        <span>Post ID</span>
                    </div>
                </TableHead>
                <TableHead>
                    <div class="flex h-full w-full items-center gap-1 dark:text-white">
                        <span class="flex flex-col gap-[1px] leading-none">
                            <span> &#x25B2;</span>
                            <span> &#x25BC; </span>
                        </span>
                        <span>Publishing Time</span>
                    </div>
                </TableHead>
                <TableHead class="text-center  dark:text-white">Media Preview</TableHead>
                <TableHead class=" dark:text-white">Caption</TableHead>
                <TableHead class="dark:text-white">Status</TableHead>
            </TableRow>
        </TableHeader>

        <TableBody class="hover:bg-transparent">
            {#each data as row}
                <TableRow class="hover:bg-transparent">
                    <TableCell class="font-medium text-[#1C1C1C] dark:text-white">{row.postId}</TableCell>
                    <TableCell>
                        <div class="group relative cursor-pointer pl-6 text-[#1C1C1C] dark:text-white">
                            {row.publishingTime}
                            <span
                                class="absolute left-1/2 top-6 hidden -translate-x-1/2 whitespace-nowrap rounded-md bg-[#232323] px-2 py-1 text-xs text-white shadow-md group-hover:block"
                            >
                                {row.publishingTime} - 18:47:29 UTC
                            </span>
                        </div>
                    </TableCell>
                    <TableCell class="flex justify-center gap-1">
                        {#each row.mediaPreview as media}
                            <img src="{media}" alt="Media Preview" class="h-8 w-8 rounded-md object-cover" />
                        {/each}
                    </TableCell>
                    <TableCell class="text-[#1C1C1C] dark:text-white">{row.caption}</TableCell>
                    <TableCell>
                        <span
                            class="whitespace-nowrap rounded-md px-2 py-1 text-xs {row.status === 'manual review'
                                ? 'bg-[#2a251a] text-[#FFA100]'
                                : 'bg-green-600'}"
                        >
                            {row.status}
                        </span>
                    </TableCell>
                </TableRow>
            {/each}
        </TableBody>
    </Table>
</div>
